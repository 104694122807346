import React, { useState, useEffect } from 'react';

function Question({ question, onOptionSelect }) {
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        // Reset selected option when the question changes
        setSelectedOption('');
    }, [question]);

    const handleRadioChange = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedOption(newSelectedOption);
        onOptionSelect(newSelectedOption);
    };

    return (
        <div className='questions-container my-2'>
            <p>{question.question}</p>
            {question.options.map((option, optionIndex) => (
                <div key={optionIndex} className='form-check'>
                    <input
                        type='radio'
                        id={`option${optionIndex}`}
                        name='options'
                        value={option}
                        className='form-check-input'
                        checked={selectedOption === option}
                        onChange={handleRadioChange}
                        required
                    />
                    <label htmlFor={`option${optionIndex}`} className='form-check-label'>
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
}

export default Question;
