import React, { useState, useEffect } from 'react';
import './App.css';
import Quiz from './Quiz';

function App() {
    const [questions, setQuestions] = useState([]);
    const [resultMessage, setResultMessage] = useState('');

    useEffect(() => {
        fetch('https://quiz.shannonjcurtis.com/getQuestions')
            .then((response) => response.json())
            .then((data) => setQuestions(data))
            .catch((error) => console.error('Error:', error));
    }, []);

    const handleSubmit = ({ answers, email }) => {
        fetch('https://quiz.shannonjcurtis.com/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ answers, email }),
        })
            .then((response) => response.json())
            .then((data) => setResultMessage(data.message ? data.message : ""))
            .catch((error) => console.error('Error:', error));
    };

    return (
        <div className="quiz-app">
            <h1 className='quiz-heading'>YOUR PERSONAL WELLBEING ASSESSMENT</h1>
            <Quiz questions={questions} onSubmit={handleSubmit} />
            {resultMessage && <><br/> <p className='result-message'>Your Results have been created and sent to your email.</p> </>}
        </div>
    );
}

export default App;
