import React, { useState } from 'react';
import Question from './Question';

function Quiz({ questions, onSubmit }) {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(Array(questions.length).fill(''));
    const [email, setEmail] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleOptionSelect = (selectedOption) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = selectedOption;
        setAnswers(newAnswers);
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleSubmit = () => {
        if (currentQuestion === questions.length - 1) {
            // If it's the last question, ask for email
            setSubmitted(true);
        } else {
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            }
        }
    };

    return (
        <>
            <div>
                {submitted ? (
                    <>
                        <h1 className='quiz-heading my-4'>Please enter your email to get your results.</h1>
                        <input className='form-control' type="email" placeholder='Enter Your Email Here' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <button className='btn btn-warning mt-3' onClick={() => onSubmit({ answers, email })}>Submit</button>
                    </>
                ) : (
                    <>
                        {currentQuestion < questions.length ? (
                            <Question
                                question={questions[currentQuestion]}
                                onOptionSelect={handleOptionSelect}
                            />
                        ) : null}

                        {currentQuestion > 0 && (
                            <button className='btn btn-secondary mx-1' onClick={handlePrevious}>Previous</button>
                        )}

                        {currentQuestion < questions.length - 1 && (
                            <button className='btn btn-warning mx-1' onClick={handleNext}>Next</button>
                        )}

                        {currentQuestion === questions.length - 1 && (
                            <button className='btn btn-warning mx-1' onClick={handleSubmit}>Submit</button>
                        )}
                    </>
                )}
            </div>
            <div>
                {resultMessage && <p>{resultMessage}</p>}
            </div>
        </>
    );
}

export default Quiz;
